import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import { Helmet } from "react-helmet";
import { customerTranslations } from "../../translations/customer";

import Hero from "../../components/modules/hero/Hero";
import MultiItem from "../../components/modules/multiItem/MultiItem";
import CaseStudy from "../../components/modules/caseStudy/CaseStudy";
import Duplex from "../../components/modules/duplex/Duplex";

import { heroProps } from "../data/customerData/heroProps";
import { caseStudyProps } from "../data/customerData/caseStudyProps";
import { multiItemProps } from "../data/customerData/multiItemProps";
import { duplexProps } from "../data/customerData/duplexProps";

export const query = graphql`
    query($id: String!, $locale: String!) {
        contentfulCustomer(id: { eq: $id }) {
            id
            title
            slug
            node_locale
            updatedAt
            createdAt

            # --- Hero --- #
            heroHeading {
                raw
            }

            # --- Case Study --- #
            caseStudyLogo {
                # fluid (maxWidth: 240)
                public_id
                width
                height
                format
            }
            caseStudyLogoAltText
            caseStudyVideo
            caseStudyMainImage {
                # fluid (maxWidth: 1100, transformations: "f_auto,q_80")
                public_id
                width
                height
                format
            }
            caseStudyMainImageAltText
            caseStudyBody {
                raw
                references {
                    ...RTEReferences
                }
            }
            caseStudyQuoteName
            caseStudyQuoteByline
            # --- Multi Item --- #
            multiItemHeading
            multiItemHeadingAlignment
            multiItemSubRichText1 {
                raw
                references {
                    ...RTEReferences
                }
            }
            multiItemSubRichText2 {
                raw
                references {
                    ...RTEReferences
                }
            }
            multiItemSubRichText3 {
                raw
                references {
                    ...RTEReferences
                }
            }

            # --- Duplex --- #
            duplexHeading {
                raw
                references {
                    ...RTEReferences
                }
            }
            duplexColumnOne {
                raw
                references {
                    ...RTEReferences
                }
            }
            duplexColumnTwo {
                raw
                references {
                    ...RTEReferences
                }
            }
        }
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

export default function Customer({ data }) {
    const customer = data.contentfulCustomer;
    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    const styleProps = {};

    // Add component fields into an object so we can pass them to the modules
    // Hero fields
    const heroFieldProps = {
        heroHeading: customer.heroHeading,
    };

    // Case Study fields
    const caseStudyFieldProps = {
        logo: customer.caseStudyLogo,
        logoAltText: customer.caseStudyLogoAltText,
        video: customer.caseStudyVideo,
        mainImage: customer.caseStudyMainImage,
        mainImageAltText: customer.caseStudyMainImageAltText,
        body: customer.caseStudyBody,
        quoteName: customer.caseStudyQuoteName,
        quoteByline: customer.caseStudyQuoteByline,
    };

    // Multi Item fields
    let itemsPerRow = 3;

    if (!customer[`multiItemSubRichText3`]) {
        itemsPerRow--;
    }
    if (!customer[`multiItemSubRichText2`]) {
        itemsPerRow--;
    }

    const multiItemFieldProps = {
        itemHeading: customer.multiItemHeading,
        itemsPerRow: itemsPerRow,
        items: [
            { __typename: "ContentfulSubRichText", contentBody: customer[`multiItemSubRichText1`] },
            { __typename: "ContentfulSubRichText", contentBody: customer[`multiItemSubRichText2`] },
            { __typename: "ContentfulSubRichText", contentBody: customer[`multiItemSubRichText3`] },
        ],
    };

    // Duplex fields
    // Check if any of the duplex module fields are populated, then show the module
    const showDuplex = customer.duplexHeading || customer.duplexColumnOne || customer.duplexColumnTwo;
    const duplexFieldProps = showDuplex && {
        heading: customer.duplexHeading,
        columnOne: [{ __typename: "ContentfulMicroRichText", content: customer.duplexColumnOne }],
        columnTwo: [{ __typename: "ContentfulMicroRichText", content: customer.duplexColumnTwo }],
    };

    return (
        <Layout
            headerMenu={data.contentfulMenu}
            footerMenu={footerData}
            notificationBar={customer.notificationBar}
            pageStyleProps={styleProps}
        >
            <SEO
                title={customer.title}
                pathname={customer.slug}
                description={customerTranslations[customer.node_locale].metaDescription}
                modifiedTime={customer.updatedAt}
                createdTime={customer.createdAt}
                languages={["All"]}
            />
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            <Hero {...heroProps} {...heroFieldProps} />
            <CaseStudy {...caseStudyProps} {...caseStudyFieldProps} />
            <MultiItem {...multiItemProps} {...multiItemFieldProps} rowsShown={false} />
            {showDuplex && <Duplex {...duplexProps} {...duplexFieldProps} />}
        </Layout>
    );
}
