// extracted by mini-css-extract-plugin
export var author = "caseStudy-module--author--5e3fc";
export var bgImage = "caseStudy-module--bgImage--aeb42";
export var byline = "caseStudy-module--byline--4c813";
export var caseStudy = "caseStudy-module--caseStudy--3cb0e";
export var copy = "caseStudy-module--copy--92201";
export var ctaSection = "caseStudy-module--ctaSection--0ec72";
export var inner = "caseStudy-module--inner--1c080";
export var media = "caseStudy-module--media--86aa5";
export var mediaFirst = "caseStudy-module--mediaFirst--9bf83";
export var moduleAlignCenter = "caseStudy-module--moduleAlignCenter--48b8a";
export var moduleAlignLeft = "caseStudy-module--moduleAlignLeft--4b16d";
export var moduleAlignRight = "caseStudy-module--moduleAlignRight--6d2c1";
export var quote = "caseStudy-module--quote--cb7d0";
export var quoteBody = "caseStudy-module--quoteBody--70972";
export var quoteIntro = "caseStudy-module--quoteIntro--89005";
export var textAlignCenter = "caseStudy-module--textAlignCenter--c2f8c";
export var textAlignLeft = "caseStudy-module--textAlignLeft--02aea";
export var textAlignRight = "caseStudy-module--textAlignRight--739cc";
export var textShadeDark = "caseStudy-module--textShadeDark--ad15e";
export var textShadeLight = "caseStudy-module--textShadeLight--1b6a1";
export var video = "caseStudy-module--video--06eac";