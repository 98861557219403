import React from "react";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import classNames from "classnames/bind";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import CTA from "../../cta/Cta";
import BaseHero from "@v4/gatsby-theme-talend/src/components/modules/hero/Hero";

import * as heroStyles from "./hero.module.css";
const cx = classNames.bind(heroStyles);

const Hero = (props) => (
    <BaseHero
        Breadcrumbs={Breadcrumbs}
        CTA={CTA}
        formatContentfulRichText={formatContentfulRichText}
        cx={cx}
        {...props}
    />
);

export default Hero;
