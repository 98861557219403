export const caseStudyProps = {
    __typename: "ContentfulCaseStudy",
    id: "97f3b5c9-c358-5f42-bf34-fd9acd4259f5",
    name: "Solutions: Astrazeneca",
    stylingOptions: {
        textAlignment: "Center",
        moduleAlignment: "Center",
        bgColor: "Seashell",
    },
    imagePosition: "Second",
};
