import React from "react";
import { graphql } from "gatsby";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import classnames from "classnames/bind";
import stylingOptions from "@v4/utils/stylingOptions";
import CTA from "@v4/talend/src/components/cta/Cta";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";

import * as caseStudyStyles from "./caseStudy.module.css";
import { useI18n } from "@v4/utils/i18nContext";
import Video from "@v4/gatsby-theme-talend/src/components/Video/Video";
const cx = classnames.bind(caseStudyStyles);

export const fragment = graphql`
    fragment CaseStudy on ContentfulCaseStudy {
        id
        name
        stylingOptions {
            ...stylingOptions
        }
        imagePosition
        logo {
            # fluid (maxWidth: 240)
            public_id
            width
            height
            format
        }
        logoAltText
        mainImage {
            # fluid (maxWidth: 1100)
            public_id
            width
            height
            format
        }
        mainImageAltText
        body {
            raw
            references {
                ...RTEReferences
            }
        }
        video
        intro {
            raw
            references {
                ...RTEReferences
            }
        }
        quoteName
        quoteByline
        ctas {
            ...Cta
        }
    }
`;

function CaseStudy(props) {
    // Styling Options:
    const styleOpts = stylingOptions(props.stylingOptions, cx);

    // Define classname for media order
    const mediaOrder = props.imagePosition ? `media${props.imagePosition}` : false;

    // Handle images
    const logoImage = props.logo ? props.logo[0] : false;
    const logoXPos = styleOpts.options && styleOpts.options.moduleAlign === "moduleAlignRight" ? "100%" : "50%";
    const mainImage = props.mainImage ? props.mainImage[0] : false;

    // Get two-character language code for blockquote (ie: "fr")
    const { i18n } = useI18n();
    // Get Language for quote styles
    let lang = i18n.curr.langCode;
    if (lang === "it") {
        // If language is Italian, reset to default Quote Styles
        lang = "en";
    }

    // Handle rich text
    const body = props.body && props.body.raw ? renderRichText(props.body, formatContentfulRichText()) : null;
    const intro = props.intro && props.intro.raw ? renderRichText(props.intro, formatContentfulRichText()) : null;

    return (
        <section
            id={props.moduleId}
            className={`${cx("caseStudy", "wrapper", mediaOrder)} ${styleOpts.classString}`}
            style={styleOpts.bgHex}
        >
            {styleOpts && styleOpts.borderTopEl}
            <div className={cx("inner")}>
                <div className={cx("copy")}>
                    {logoImage && (
                        <Image
                            placeholderStyle={{ filter: `blur(10px)` }}
                            image={logoImage}
                            imgStyle={{
                                objectFit: "contain",
                                objectPosition: `${logoXPos} 50%`,
                                maxHeight: "100%",
                            }}
                            alt={props.logoAltText}
                            style={{ width: "100%", maxWidth: "240px", maxHeight: "80px", justifyContent: "center" }}
                        />
                    )}
                    {intro && <div className={cx("quoteIntro")}>{intro}</div>}
                    {body && (
                        <blockquote lang={lang} className={cx("quote")}>
                            <div className={cx("quoteBody")}>{body}</div>
                            {(props.quoteByline || props.quoteName) && (
                                <footer>
                                    <p className={cx("author")}>{props.quoteName}</p>
                                    <p className={cx("byline")}>{props.quoteByline}</p>
                                </footer>
                            )}
                        </blockquote>
                    )}
                    {props.ctas && (
                        <div className={cx(`ctaSection`)}>
                            {props.ctas.map((cta, index) => (
                                <CTA key={`cta-${index}`} {...cta} />
                            ))}
                        </div>
                    )}
                </div>
                <div className={cx("media")}>
                    {props.video ? (
                        <Video videoSrcURL={props.video} videoTitle={props.videoTitle} customClass={cx("video")} />
                    ) : (
                        mainImage && (
                            <Image
                                placeholderStyle={{ filter: `blur(10px)` }}
                                image={mainImage}
                                imgStyle={{
                                    objectFit: "cover",
                                    objectPosition: "50% 50%",
                                }}
                                alt={props.mainImageAltText}
                                style={{ width: "100%", height: "100%" }}
                            />
                        )
                    )}
                </div>
            </div>
            {styleOpts && styleOpts.bgImageEl}
            {styleOpts && styleOpts.borderBottomEl}
        </section>
    );
}

export default CaseStudy;
