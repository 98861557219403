export const duplexProps = {
    stylingOptions: {
        textShade: "Dark",
        textAlignment: "Left",
        spacingBottom: "M",
        spacingTop: "M",
        bgColor: "White",
    },
    columnSplit: "6/6",
};
