import React from "react";
import { graphql } from "gatsby";
import Image from "../../Image/Image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import stylingOptions from "@v4/utils/stylingOptions";
import getTextAlignClassName from "@v4/utils/getTextAlignClassName";

export const fragment = graphql`
    fragment Hero on ContentfulHero {
        id
        heroHeading: heading {
            raw
            references {
                ...RTEReferences
            }
        }
        headingAlignment
        heroSubHeading: subHeading {
            raw
            references {
                ...RTEReferences
            }
        }
        subHeadingAlignment
        stylingOptions {
            ...stylingOptions
        }
        ctaAlignment
        cta {
            id
            ...Cta
        }
        footNote
        heroImage: image {
            public_id
            width # Max display width: 450px
            height
            format
        }
        imageAltText
        imageVertAlignment
        imageHorzAlignment
        heroBgImage: bgImage {
            public_id
            width # Max display width: 1280px
            height
            format
        }
        bgPosition
        bgSize
        bgRepeat
        heroLayout
        breadcrumbLinks {
            __typename
            ... on ContentfulMenuItem {
                id
                title
                description
                variant
                url
                newWindow
                pageLink {
                    ...PageLink
                }
            }
        }
        data {
            internal {
                content
            }
        }
    }
`;

const Hero = ({ Breadcrumbs, CTA, formatContentfulRichText, cx, CustomMediaComponent = false, ...props }) => {
    // Allow overrides of styling options from fields directly on the Hero content type
    const styleProps = props.stylingOptions ?? {};

    // Check which hero layout to use and define it as a const. Default to horizontal if it's not specified
    const heroLayout = props.heroLayout
        ? props.heroLayout.toLowerCase() === "horizontal with breadcrumb"
            ? "horizontal-breadcrumb"
            : props.heroLayout.toLowerCase()
        : "horizontal";

    if (props.heroBgImage) {
        styleProps.bgImage = props.heroBgImage;
    } else if (props.stylingOptions && props.stylingOptions.bgImage) {
        styleProps.bgImage = props.stylingOptions.bgImage;
    }

    if (props.bgPosition) {
        styleProps.bgPosition = props.bgPosition;
    } else if (props.stylingOptions && props.stylingOptions.bgPosition) {
        styleProps.bgPosition = props.stylingOptions.bgPosition;
    }

    if (props.bgSize) {
        styleProps.bgSize = props.bgSize;
    } else if (props.stylingOptions && props.stylingOptions.bgSize) {
        styleProps.bgSize = props.stylingOptions.bgSize;
    }

    if (props.bgRepeat) {
        styleProps.bgRepeat = props.bgRepeat;
    } else if (props.stylingOptions && props.stylingOptions.bgRepeat) {
        styleProps.bgRepeat = props.stylingOptions.bgRepeat;
    }

    // Set up styling options
    const styleOpts = stylingOptions(styleProps, cx) ?? "";

    // Heading & Sub Heading alignment options for cx classes:
    const hAlign = getTextAlignClassName(props.headingAlignment);
    const bAlign = getTextAlignClassName(props.subHeadingAlignment);

    // Get content for Heading and Sub Heading, integrate alignment options
    const heading = props.heroHeading ? (
        <div className={cx("heading", hAlign)}>{renderRichText(props.heroHeading, formatContentfulRichText())}</div>
    ) : null;
    const subHeading = props.heroSubHeading ? (
        <div className={cx("subHeading", bAlign)}>
            {renderRichText(props.heroSubHeading, formatContentfulRichText())}
        </div>
    ) : null;

    // Set up image object for passing to our image handler
    const image = props.heroImage ? props.heroImage[0] : false;

    // Vertical and Horizontal alignment settings for image
    const imageWrapperStyles = [];

    if (props.imageHorzAlignment === "Left") {
        imageWrapperStyles.push("justify-start");
    } else if (props.imageHorzAlignment === "Right") {
        imageWrapperStyles.push("desktop:justify-end");
    } else {
        imageWrapperStyles.push("justify-center");
    }

    // If vertical aligment is "Top", use Flex-Start, else if it's "Bottom", use Flex-End. Defaults to "center".
    if (props.imageVertAlignment === "Top") {
        imageWrapperStyles.push("items-start");
    } else if (props.imageVertAlignment === "Bottom") {
        imageWrapperStyles.push("items-end");
    } else {
        imageWrapperStyles.push("items-center");
    }

    // Declare alignment outside of component for tailwind purge compatibility
    const ctaAlign = props.ctaAlignment ? `text-${String(props.ctaAlignment).toLowerCase()}` : "";

    // Create a crumbList object to pass to the Breadcrumb component
    let crumbList = {};

    props.breadcrumbLinks &&
        props.breadcrumbLinks.forEach((crumb) => {
            crumbList[crumb.title] = crumb.url ? crumb.url : crumb.pageLink?.slug ? crumb.pageLink.slug : null;
        });

    // Check if we should display the breadcrumb. Check if the heroLayout is set properly and the crumbList isn't empty.
    const shouldDisplayBreadcrumb = heroLayout === "horizontal-breadcrumb" && Object.keys(crumbList).length > 0;

    return (
        <section
            id={props.moduleId}
            className={`${cx("hero")} ${styleOpts && styleOpts.classString}`}
            style={styleOpts.bgHex}
        >
            {styleOpts && styleOpts.borderTopEl}
            <div className={`${cx("content", heroLayout)}`}>
                <div className={cx("copy")}>
                    {heading}
                    {subHeading}
                    {shouldDisplayBreadcrumb && (
                        <div className={cx("bcContainer")}>
                            <Breadcrumbs crumbList={crumbList} />
                        </div>
                    )}
                    {props.cta && (
                        <div className={`${cx("ctaContainer", ctaAlign)} ${ctaAlign}`}>
                            {props.cta.map((cta) => (
                                <CTA key={cta.id} {...cta} />
                            ))}
                        </div>
                    )}
                    {props.footNote && <div className={"pt-1 font-light"}>{props.footNote}</div>}
                </div>
                <div className={cx("media")}>
                    {CustomMediaComponent === false && image && (
                        <div className={cx("image", imageWrapperStyles)}>
                            <Image
                                image={image}
                                alt={props.imageAltText}
                                width={image.width / 2}
                                height={image.height / 2}
                                loadMethod="eager"
                            />
                        </div>
                    )}
                    {CustomMediaComponent !== false && <CustomMediaComponent />}
                </div>
            </div>
            {styleOpts && styleOpts.bgImage && styleOpts.bgImageEl}
            {styleOpts && styleOpts.borderBottomEl}
        </section>
    );
};

export default Hero;
