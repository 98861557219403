import { commonTranslations as common } from "@v4/gatsby-theme-talend/src/translations/common";

export const customerTranslations = {
    en: {
        metaDescription: "Find out how Talend helps customers turn better data into successful business outcomes.",
        viewMore: common.en.viewMore,
        toTop: common.en.toTop,
        readStory: "Read story",
        readMore: "Read more",
    },
    "en-GB": {
        metaDescription: "Find out how Talend helps customers turn better data into successful business outcomes.",
        viewMore: common["en-GB"].viewMore,
        toTop: common["en-GB"].toTop,
        readStory: "Read story",
        readMore: "Read more",
    },
    fr: {
        metaDescription:
            "Découvrez comment Talend aide ses clients à convertir leurs données en résultats commerciaux fructueux pour leur entreprise.",
        viewMore: common.fr.viewMore,
        toTop: common.fr.toTop,
        readStory: "Lire l'article",
        readMore: "En savoir plus",
    },
    de: {
        metaDescription:
            "Finden Sie heraus, wie Talend seinen Kunden dabei hilft, mit hochwertigen Daten bessere Geschäftsergebnisse zu erzielen.",
        viewMore: common.de.viewMore,
        toTop: common.de.toTop,
        readStory: "Story lesen",
        readMore: "Weitere Informationen",
    },
    it: {
        metaDescription: "Scopri come Talend aiuta i clienti a trasformare i dati in ottimi risultati aziendali.",
        viewMore: common.it.viewMore,
        toTop: common.it.toTop,
        readStory: "Leggi la testimonianza",
        readMore: "Leggi di più",
    },
    ja: {
        metaDescription:
            "Talendがどのようにして、お客様の優れたデータをビジネス上の成果へと変えるお手伝いをしているのかをご確認ください。",
        viewMore: common.ja.viewMore,
        toTop: common.ja.toTop,
        readStory: "ストーリーを読む",
        readMore: "さらに読む",
    },
};
