export const heroProps = {
    stylingOptions: {
        textShade: "Light",
        textAlignment: "Left",
        spacingBottom: "M",
        spacingTop: "M",
        bgGradient: "Customer Gradient",
    },
    heroImage: [
        {
            format: "png",
            src: "https://res.cloudinary.com/talend/image/upload/v1617743736/hero/hero-academy-learning_skdais.png",
            public_id: "hero/hero-academy-learning_skdais",
            width: "470",
            height: "460",
        },
    ],
    imageVertAlignment: "Center",
    imageHorzAlignment: "Right",
};
