// extracted by mini-css-extract-plugin
export var bcContainer = "hero-module--bcContainer--36933";
export var content = "hero-module--content--6942c";
export var copy = "hero-module--copy--034b5";
export var ctaContainer = "hero-module--ctaContainer--3f81d";
export var heading = "hero-module--heading--ef93e";
export var hero = "hero-module--hero--67038";
export var image = "hero-module--image--021db";
export var media = "hero-module--media--eacd1";
export var subHeading = "hero-module--subHeading--25180";
export var vertical = "hero-module--vertical--73b78";