export const multiItemProps = {
    name: "Customer profile Test: astrazeneca",
    stylingOptions: {
        textShade: "Dark",
        textAlignment: "Center",
        spacingBottom: "M",
        spacingTop: "M",
        borderBottom: ["True"],
        borderBottomHex: "d1d1d1",
    },
    copyWidth: "75%",
    itemsAlignment: "Center",
};
